export const categoryDetailsQuery = (id) => `{category(id: ${id}) {data {id  attributes {name} id } }}`;
export const productDetailsQuery = (productID) =>
  `{
  product(id: ${productID}) {
    data {
      id
      attributes {
        name
        sd_plus_interventions
        environmental_impact_ecosystem_health
        environmental_impact_human_health
        environmental_impact_circularity
        environmental_impact_climate_health
        quality_assurance_reliability
        warranty_after_sales
        product_code
        references
    		sdgs
        brand{
          data{
            attributes{
              name
              manufacturing_locations
              region_of_operation
            }
          }
        }
        shortDescription
        image
        productDescription
        price
        brochure
        identifier
        environmental_impacts {
          id
          title
          type
          items
        }
        certifications_and_standards {
          id
          title
          type
          items
        }
        properties {
          id
          title
          type
          items
        }
        general {
          id
          title
          type
          items
        }
        company_details
      }
    }
  }
}`;
